import React from 'react'
import Layout from '../layout/Layout'
import '../styles/index.css'
import BookingCta from './components/home/BookingCta'
import Hero from './components/home/Hero'
import PamperHero from './components/home/PamperHero'
import Services from './components/home/Services'
import Welcome from './components/home/Welcome'
import CustomerTestimonialsCarousel from './components/shared/CustomerTestimonialsCarousel'
import Footer from './components/shared/Footer'
import NavBar from './components/shared/NavBar'

const IndexPage = () => {
  return (
    <Layout>
      <NavBar />
      <Hero />
      <Welcome />
      <Services />
      <div className="mustard-divider"></div>
      <PamperHero />
      <BookingCta />
      <CustomerTestimonialsCarousel />
      <Footer />
    </Layout>
  )
}

export default IndexPage
